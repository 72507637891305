import React, { useRef } from "react"
import Layout from "../components/common/layout"
import SEO from "../components/seo"
import { graphql, useStaticQuery } from "gatsby"
import ContactSection from "../components/contact/contact-section"

const TermsPage = () => {
  const contactRef = useRef(null)
  const data = useStaticQuery(graphql`
    query TermsPageQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)

  return (
    <>
      <SEO
        title={"Términos"}
        description={data.site.siteMetadata.description}
        keywords={["terms", "terminos"]}
        url={"https://www.waravix.com/terms"}
      />
      <Layout currentPage={"terms"}>
        <ContactSection contactRef={contactRef} />
      </Layout>
    </>
  )
}

export default TermsPage
